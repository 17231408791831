import React 	from "react"
import Layout	from "../components/layout"
import Bild   from "../components/myImage4"

const Index = () => (
	<Layout head_gif="home_bhb.png">
		<h1 align="center">Willkommen an der Nordseeküste</h1>
		<Bild my_img="haus.jpg" />
		<h2 align="center">Willkommen in Mariensiel</h2>
		<div className="row">
			{/*	  <div className="col-md-1"> 		</div> funkt im Build nicht */}
			<p>
				Mariensiel liegt direkt am Jadebusen an der Nordsee. Nach 10 min Spaziergang kann man
				hier Ebbe und Flut am besten beobachten. Nach ca. 6 ½ Stunden wechselt die Flut
				mit der Ebbe.
				Nirgendwo kann man es besser beobachten als am Jadebusen. Alle 13 Std. ist er komplett
				leer.
			</p><p>
				Das Mariensiel wurde 1575 von der Regentin Fräulein Maria von Jever gegründet.
			</p><p>
				Es diente zu Entwässerung der Jeverlandes.
				Was ist ein Siel?
				Durch ein Siel fließt das Binnenwasser durch den Deich ins Meer. Mit dem Küstenschutz
				stellt sich gleichzeitig das Problem der Entwässerung. Denn die natürlichen
				Abflüsse vom Hinterland ins Meer werden durch die Deichbauten blockiert.
				Das Binnenland ist von einem dichten, im Gelände gut sichtbaren, Grabennetz durchzogen.
			</p><p>
				Bei ablaufenden Wasser (Ebbe) öffnen
				sich durch die Schrägstellung die Sieltore und das aufgestaute Binnenwasser
				kann abfließen.
				Durch die Flut werden die Tore automatisch wieder verschlossen. Heute
				werden diese Aufgaben von großen Schöpfwerken z.B. Harlesiel übernommen.Das
				Mariensiel wurde 1963 außer Betrieb genommen. Das Siel ist als Denkmal
				vorhanden und
				kann als einziges von innen besichtigt werden.
			</p>
		</div>
		<div className="row">
			<div className="col-md-2"></div>
			<div className="col-md-8">
				<h2 align="center">Herzlichst - Ihre Gastgeber <br />Jutta & Ferdinand Meierhoff</h2>
				<Bild my_img="portrait.jpg" />
			</div>
			<div className="col-md-2"></div>
		</div>
	</Layout>
);
export default Index;
